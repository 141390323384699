/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-19 10:34:48
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-20 11:20:09
 */
import Vue from 'vue'

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

// 设置cookie
export function setCookie (name, value, days) {
  var expires = new Date()
  expires.setDate(expires.getDate() + days)
  document.cookie = name + '=' + value + '; expires=' + expires.toUTCString()
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}
// 获取当前时间
export function getCurrentTime () {
  const dd = new Date()
  const y = dd.getFullYear()
  let m = dd.getMonth() + 1
  let d = dd.getDate()
  let hour = dd.getHours()
  let min = dd.getMinutes()
  let sec = dd.getSeconds()
  m = m < 10 ? '0' + m : m
  d = d < 10 ? '0' + d : d
  hour = hour < 10 ? '0' + hour : hour
  min = min < 10 ? '0' + min : min
  sec = sec < 10 ? '0' + sec : sec
  const day = y + '-' + m + '-' + d + ' ' + hour + ':' + min + ':' + sec
  return day
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}
export function getCookie (name) {
  var strcookie = document.cookie // 获取cookie字符串
  var arrcookie = strcookie.split('; ') // 分割
  // 遍历匹配
  for (var i = 0; i < arrcookie.length; i++) {
    var arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arr[1]
    }
  }
  return ''
}
// 复制功能
export function copyText (value, that) {
  const aux = document.createElement('input')
  aux.setAttribute('value', value)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  that.$message.success('复制成功')
}
// 导出功能
export function exportBtn (api, params, fileName) {
  api(params).then((res) => {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: 'application/vnd.ms-excel' })
    )
    const nameData = fileName
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    // a.setAttribute('download', name + '.' + elxeName)
    a.setAttribute('download', nameData)
    document.body.appendChild(a)
    a.click() // 执行下载
    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
  })
}
// 悬浮颜色
export function hexToRgb (hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  // this.$forceUpdate()
  return result
    ? 'rgba' +
        '(' +
        parseInt(result[1], 16) +
        ',' +
        parseInt(result[2], 16) +
        ',' +
        parseInt(result[3], 16) +
        ',' +
        0.2 +
        ')'
    : null
}
// 判断的是否是JSON字符串
export const types = (str) => {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str)
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj === 'object' && obj) {
        return true
      } else {
      // 不是就返回false
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}
export function getMonth (num) {
  if (num <= 0) return
  const today = new Date()
  const year = today.getFullYear() // 当前年份
  let needYear = year - Math.floor(num / 12) // 获取到想要查询月所在的年份
  // let needYearNext = needYear // 获取到想要查询月下一个月所在的年份
  let month = today.getMonth() + 1 // 获取到当前月

  // 获取num 的余数  如果余数大于等于当前月份  则年份还要减1
  const numRemainder = num % 12
  if (numRemainder >= month) {
    needYear--
    month += 12
  }

  // 获取到所需要的月份和所需要的月份的下一个月
  let needMonth = month - numRemainder
  let needMonthNext = needMonth + 1

  // 如果所需要月份的下一个月大于12 则需要调到下一年的一月
  if (needMonthNext > 12) {
    needMonthNext = 1
  }
  if (needMonth === 0) needMonth = 12
  // 所需月份小于10 则前面补0
  if (needMonth < 10) needMonth = '0' + needMonth
  if (needMonthNext < 10) needMonthNext = '0' + needMonthNext
  // console.log()
  var datatime = new Date(needYear, needMonth, 0).getDate()
  // console.log(datatime, '当月最后一天')
  return [`${needYear}-${needMonth}-01`, `${needYear}-${needMonth}-${datatime}`]
}
export function getWeek () {
  const datTime = new Date().getTime() // 获取当前日期毫秒数
  const time = 7 * 24 * 60 * 60 * 1000 // 定义一周的毫秒数
  // const num = new Date(datTime - time).getDay() === 0 ? 7 : new Date(datTime - time).getDay() // 注：（0-6）0为周日； 用这周时间减去一周时间 得到上周时间  可能出现为周日（0） 的情况，用三目加以判断
  const num = new Date(datTime - time).getDay() // 注：（0-6）0为周日； 用这周时间减去一周时间 得到上周时间  可能出现为周日（0） 的情况，用三目加以判断
  // 上上周日-上周六
  // let numRI = new Date(datTime - time * 2 + ((7 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上周日时间（'/'替换'-',美观作用）
  // let numRI1 = new Date(datTime - time + ((6 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上周6时间（'/'替换'-',美观作用）
  // 上上上周日-上上周六
  // let numRIT = new Date(datTime - (time * 3) + ((7 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上上周日时间（'/'替换'-',美观作用）
  // let numRI1T = new Date(datTime - time * 2 + ((6 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上周6时间（'/'替换'-',美观作用）
  // 上周日-本周六
  // let numRITT = new Date(datTime - (time) + ((7 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上周日时间（'/'替换'-',美观作用）
  // let numRI1TT = new Date(datTime + ((6 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取这周6时间（'/'替换'-',美观作用）

  // 本周新-（上周六-本周五）
  let numRITT = new Date(datTime - (time) + ((6 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上周日时间（'/'替换'-',美观作用）
  let numRI1TT = new Date(datTime + ((5 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取这周6时间（'/'替换'-',美观作用）
  // 上周新-（上上周六-上周五）
  let numRI = new Date(datTime - time * 2 + ((6 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上周日时间（'/'替换'-',美观作用）
  let numRI1 = new Date(datTime - time + ((5 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上周6时间（'/'替换'-',美观作用）
  // 上上周（上上上周六-上上周五）
  let numRIT = new Date(datTime - (time * 3) + ((6 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上上周日时间（'/'替换'-',美观作用）
  let numRI1T = new Date(datTime - time * 2 + ((5 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上周6时间（'/'替换'-',美观作用）
  // 上上周一-上上周三
  let lastLastMonday = new Date(datTime - time * 2 + ((1 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上周1时间（'/'替换'-',美观作用）
  let lastLastWensday = new Date(datTime - time * 2 + ((3 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上上周3时间（'/'替换'-',美观作用）

  // 上周一-上周三
  let lastMonday = new Date(datTime - time + ((1 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-')
  let lastWensday = new Date(datTime - time + ((3 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-')
  // 本周一-本周三
  let monday = new Date(datTime + ((1 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-')
  let wensday = new Date(datTime + ((3 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-')
  numRI = numRI.split('-')
  numRI1 = numRI1.split('-')
  numRIT = numRIT.split('-')
  numRI1T = numRI1T.split('-')
  numRITT = numRITT.split('-')
  numRI1TT = numRI1TT.split('-')
  // 同期对比
  lastMonday = lastMonday.split('-')
  lastWensday = lastWensday.split('-')
  monday = monday.split('-')
  wensday = wensday.split('-')
  // 周期对比（上上周一-上上周三）
  lastLastMonday = lastLastMonday.split('-')
  lastLastWensday = lastLastWensday.split('-')

  if (Number(numRI[1]) < 10) {
    numRI[1] = '0' + numRI[1]
  }
  if (Number(numRI[2]) < 10) {
    numRI[2] = '0' + numRI[2]
  }
  if (Number(numRI1[1]) < 10) {
    numRI1[1] = '0' + numRI1[1]
  }
  if (Number(numRI1[2]) < 10) {
    numRI1[2] = '0' + numRI1[2]
  }

  if (Number(numRIT[1]) < 10) {
    numRIT[1] = '0' + numRIT[1]
  }
  if (Number(numRIT[2]) < 10) {
    numRIT[2] = '0' + numRIT[2]
  }
  if (Number(numRI1T[1]) < 10) {
    numRI1T[1] = '0' + numRI1T[1]
  }
  if (Number(numRI1T[2]) < 10) {
    numRI1T[2] = '0' + numRI1T[2]
  }

  if (Number(numRITT[1]) < 10) {
    numRITT[1] = '0' + numRITT[1]
  }
  if (Number(numRITT[2]) < 10) {
    numRITT[2] = '0' + numRITT[2]
  }
  if (Number(numRI1TT[1]) < 10) {
    numRI1TT[1] = '0' + numRI1TT[1]
  }
  if (Number(numRI1TT[2]) < 10) {
    numRI1TT[2] = '0' + numRI1TT[2]
  }
  // 同期对比
  if (Number(lastMonday[1]) < 10) {
    lastMonday[1] = '0' + lastMonday[1]
  }
  if (Number(lastMonday[2]) < 10) {
    lastMonday[2] = '0' + lastMonday[2]
  }
  if (Number(lastWensday[1]) < 10) {
    lastWensday[1] = '0' + lastWensday[1]
  }
  if (Number(lastWensday[2]) < 10) {
    lastWensday[2] = '0' + lastWensday[2]
  }
  if (Number(monday[1]) < 10) {
    monday[1] = '0' + monday[1]
  }
  if (Number(monday[2]) < 10) {
    monday[2] = '0' + monday[2]
  }
  if (Number(wensday[1]) < 10) {
    wensday[1] = '0' + wensday[1]
  }
  if (Number(wensday[2]) < 10) {
    wensday[2] = '0' + wensday[2]
  }
  // 周期对比
  if (Number(lastLastMonday[1]) < 10) {
    lastLastMonday[1] = '0' + lastLastMonday[1]
  }
  if (Number(lastLastMonday[2]) < 10) {
    lastLastMonday[2] = '0' + lastLastMonday[2]
  }
  if (Number(lastLastWensday[1]) < 10) {
    lastLastWensday[1] = '0' + lastLastWensday[1]
  }
  if (Number(lastLastWensday[2]) < 10) {
    lastLastWensday[2] = '0' + lastLastWensday[2]
  }

  // console.log(lastMonday, 'lastMonday')
  // console.log(lastWensday, 'lastWensday')
  // console.log(monday, 'monday')
  // console.log(wensday, 'wensday')
  numRI = numRI.join('-')
  numRI1 = numRI1.join('-')
  numRIT = numRIT.join('-')
  numRI1T = numRI1T.join('-')
  numRITT = numRITT.join('-')
  numRI1TT = numRI1TT.join('-')
  // console.log(numRI, '时间')
  // 同期对比
  lastMonday = lastMonday.join('-')
  lastWensday = lastWensday.join('-')
  monday = monday.join('-')
  wensday = wensday.join('-')
  // 周期对比
  lastLastMonday = lastLastMonday.join('-')
  lastLastWensday = lastLastWensday.join('-')

  return [{
    numRI,
    numRI1
  }, {
    numRIT,
    numRI1T
  }, {
    numRITT,
    numRI1TT
  }, {
    lastMonday,
    lastWensday
  }, {
    monday,
    wensday
  }, {
    lastLastMonday,
    lastLastWensday
  }
  ]
}

// 获取当天日期
export function getDay (day) {
  var today = new Date()
  var targetdayMilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetdayMilliseconds) // 注意，这行是关键代码
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
}
// 获取当月一号日期
export function getDayOfTheFirstDay () {
  var today = new Date()
  var targetdayMilliseconds = today.getTime()
  today.setTime(targetdayMilliseconds) // 注意，这行是关键代码
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = '01'
  tMonth = doHandleMonth(tMonth + 1)
  return tYear + '-' + tMonth + '-' + tDate
}

function doHandleMonth (month) {
  var m = month
  if (month.toString().length === 1) {
    m = '0' + month
  }
  return m
}

export function getMonthTime () {
  var nowdays = new Date()
  var year = nowdays.getFullYear()
  var month = nowdays.getMonth()
  if (month === 0) {
    month = 12
    year = year - 1
  }
  if (month < 10) {
    month = '0' + month
  }
  var myDate = new Date(year, month, 0)
  var startDate = year + '-' + month + '-01 00:00:00' // 上个月第一天
  var endDate = year + '-' + month + '-' + myDate.getDate() + ' 23:59:00' // 上个月最后一天
  return [startDate, endDate]
}
export function getDateRangeBtn () {
  const datTime = new Date().getTime() // 获取当前日期毫秒数
  const time = 7 * 24 * 60 * 60 * 1000 // 定义一周的毫秒数
  const num = new Date(datTime - time).getDay() // 注：（0-6）0为周日； 用这周时间减去一周时间 得到上周时间  可能出现为周日（0） 的情况，用三目加以判断
  // 上（周一-周日）
  let lastMonday = new Date(datTime - (time) + ((1 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上周1时间（'/'替换'-',美观作用）
  let lastSunday = new Date(datTime - (time) + ((7 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-') // 获取上周日时间（'/'替换'-',美观作用）
  // （周一-周日）
  let monday = new Date(datTime + ((1 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-')
  let sunday = new Date(datTime + ((7 - num) * 24 * 60 * 60 * 1000)).toLocaleDateString().replace(/\//g, '-')
  lastMonday = lastMonday.split('-')
  lastSunday = lastSunday.split('-')
  monday = monday.split('-')
  sunday = sunday.split('-')
  if (Number(lastMonday[1]) < 10) {
    lastMonday[1] = '0' + lastMonday[1]
  }
  if (Number(lastMonday[2]) < 10) {
    lastMonday[2] = '0' + lastMonday[2]
  }
  if (Number(lastSunday[1]) < 10) {
    lastSunday[1] = '0' + lastSunday[1]
  }
  if (Number(lastSunday[2]) < 10) {
    lastSunday[2] = '0' + lastSunday[2]
  }
  if (Number(monday[1]) < 10) {
    monday[1] = '0' + monday[1]
  }
  if (Number(monday[2]) < 10) {
    monday[2] = '0' + monday[2]
  }
  if (Number(sunday[1]) < 10) {
    sunday[1] = '0' + sunday[1]
  }
  if (Number(sunday[2]) < 10) {
    sunday[2] = '0' + sunday[2]
  }
  lastMonday = lastMonday.join('-')
  lastSunday = lastSunday.join('-')
  monday = monday.join('-')
  sunday = sunday.join('-')
  return [{
    lastMonday,
    lastSunday
  }, {
    monday,
    sunday
  }
  ]
}

// 获取图片信息
export function getImage (arr) {
  let resData = []
  const fileArr = JSON.parse(JSON.stringify(arr))
  fileArr.forEach(el => {
    if (el.response) {
      resData.push(el.response.data[0].url)
    } else {
      resData.push(el.url)
    }
  })
  resData = resData.map(item => (item))
  return resData
}

// 获取数据类型
Vue.prototype.$getType = (obj) => {
  // tostring会返回对应不同的标签的构造函数
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}

// 深度拷贝
Vue.prototype.$deepClone = (sourceData) => {
  // const dataType = Vue.prototype.$getType(options);
  // const result = dataType === "array" ? [] : {};
  // return $.extend(true, result, options);
  let obj = {}
  if (Array.isArray(sourceData)) {
    return sourceData.map(item => Vue.prototype.$deepClone(item))
  } else if (Vue.prototype.$getType(sourceData) === 'object') {
    for (const key in sourceData) {
      if ((typeof sourceData[key] === 'object') && sourceData[key] !== null) {
        obj[key] = this.deepCopy(sourceData[key])
      } else {
        obj[key] = sourceData[key]
      }
    }
  } else {
    obj = sourceData
  }
  return obj
}
