/*
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-09-07 10:25:55
 * @LastEditors: wenwen
 * @LastEditTime: 2024-07-09 09:29:16
 */
import store from '@/store'
export function hasPermission (permission) {
  console.log(store.getters.getPermission, 'quanxian-------------')
  const myPermissions = store.getters.getPermission || []
  return myPermissions.indexOf(permission) > -1
}
