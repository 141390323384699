<!--
 * @Descripttion:
 * @version:
 * @Author: Eugene
 * @Date: 2023-06-05 10:39:33
 * @LastEditors: wenwen
 * @LastEditTime: 2024-05-05 09:49:01
-->
<template>
  <div id="app">
    <router-view />
    <div class="file-contents" v-if="isShowRightFiles">
      <div v-if="!isShowopen" class="title-top" @click="isShowopen=!isShowopen">
        <img src="@/assets/img/icon_upload_right.png" alt="">
      </div>
    </div>
    <div class="show-file" v-if="isShowopen && isShowRightFiles">
      <div  class="title-top" @click="isShowopen=!isShowopen">
        <div>{{(resourseFilesObj&&resourseFilesObj.fileList && resourseFilesObj.fileList.length)+(koneledgeBaseFilesObj &&  koneledgeBaseFilesObj.fileList && koneledgeBaseFilesObj.fileList.length)}}个文件正在上传中</div>
        <div>隐藏</div>
      </div>
    <div class="file-list" v-if="resourseFilesObj && resourseFilesObj.fileList.length" @click="goDetail('resource')">
      <div class="title">资源管理</div>
        <div
          class="file-item"
          v-for="(item, index) in resourseFilesObj && resourseFilesObj.fileList"
          :key="index"
          @click="goDetail('resource')"
        >
          <div class="file-icon">
            <img src="@/assets/img/video.png" alt="" />
          </div>
          <div class="file-content">
            <div class="text">
              <div>{{ item.name }}</div>
              <div>{{ Math.floor(item.percentage) + "%" }}</div>
            </div>
            <div class="progress">
              <el-progress
                v-if="item.percentage < 100"
                :percentage="item.percentage"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div class="right-icon">
            <img
              v-if="item.percentage == 100"
              class="success"
              src="@/assets/img/upload-success-icon.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="file-list" v-if="koneledgeBaseFilesObj && koneledgeBaseFilesObj.fileList.length" @click="goDetail('koneledge')">
        <div class="title">知识库</div>
        <div
          class="file-item"
          v-for="(item, index) in koneledgeBaseFilesObj && koneledgeBaseFilesObj.fileList"
          :key="index"
          @click="goDetail('koneledge')"
        >
          <div class="file-icon">
            <img src="@/assets/img/video.png" alt="" />
          </div>
          <div class="file-content">
            <div class="text">
              <div>{{ item.name }}</div>
              <div>{{ Math.floor(item.percentage) + "%" }}</div>
            </div>
            <div class="progress">
              <el-progress
                v-if="item.percentage < 100"
                :percentage="item.percentage"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div class="right-icon">
            <img
              v-if="item.percentage == 100"
              class="success"
              src="@/assets/img/upload-success-icon.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState('fileUpload', ['resourseFilesObj', 'koneledgeBaseFilesObj', 'isShowRightFiles']),
    defaultTheme () {
      return this.$store.state.settings.theme
    }

  },
  data () {
    return {
      isShowopen: true
    }
  },
  created () {
    // localStorage.removeItem('theme')
  },
  mounted () {
    // if (localStorage.getItem('theme')) {
    //   this.themeChange(localStorage.getItem('theme'))
    // }
  },
  methods: {
    goDetail (type) {
      if (type === 'resource') {
        this.$router.replace({
          path: '/uploadFile?pageType=resource&groupId='
        })
      } else {
        this.$router.replace({
          path: '/uploadFile?pageType=knowledgeBase&groupId='
        })
      }
    }
  }
}
</script>
<style>
.customClass {
  background: #ebf6ff !important;
  box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px 4px 4px 4px !important;
  border: 1px solid #0089ff !important;
}
.customClass .el-message__content {
  color: rgba(0, 0, 0, 0.9) !important;
}
.customClass .iconClass {
  background: url("./assets/img/Subtract.png") no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。 */
*::-webkit-scrollbar-button {
  display: none;
}
/* // 滚动条的轨道（里面装有Thumb） */
*::-webkit-scrollbar-track {
  background: transparent;
}
/* // 滚动条的轨道（里面装有Thumb） */
*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
/* // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条） */
*::-webkit-scrollbar-thumb {
  background: rgba(144, 147, 153, 0.3);
  cursor: pointer;
  border-radius: 4px;
}
/* // 边角，即两个滚动条的交汇处 */
*::-webkit-scrollbar-corner {
  display: none;
}
/* // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件 */
*::-webkit-resizer {
  display: none;
}
</style>
<style lang="scss" scoped>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css"; /*深色主题*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
* {
  scrollbar-color: rgba(144, 147, 153, 0.3) transparent; /* 滑块颜色  滚动条背景颜色 */
  scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
}

/deep/.hover-title {
  min-width: 120px !important;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.hover-name {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/ .el-card.is-always-shadow {
  box-shadow: none !important;
}
/deep/.el-card__header {
  padding-bottom: 0px !important;
}
/deep/.group-card .el-card__header {
  padding-bottom: 16px !important;
}
/deep/.el-dialog__header {
  padding: 16px 24px !important;
}
/deep/.el-form-item__label {
  color: rgba(0, 0, 0, 0.9) !important;
}
/deep/.add-group-btn {
  font-size: 14px;
  border-color: var(--lock-btn) !important;
  color: var(--lock-btn) !important;
}
.width-group-table {
  /deep/.el-table tr td:nth-child(1) {
    padding-left: 32px !important;
  }
}
/deep/.el-tree-node:focus > .el-tree-node__content {
  background-color: var(--lock-btn) !important;
  color: var(--lock-btn-text) !important;
}
/deep/.el-tree-node__content:hover {
  background-color: var(--lock-btn) !important;
  color: var(--lock-btn-text) !important;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: var(--lock-btn) !important;
  color: var(--lock-btn-text) !important;
}
.file-contents {
  position: fixed;
  right: 32px;
  bottom: 100px;
  z-index: 99999999;
  .title-top {
    img{
      width: 50px;
      height: 50px;
    }

  }
}
.show-file{
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 400px;
  max-height: 400px;
  overflow: auto;
  background: linear-gradient(95deg, #f6faff 0%, #ffffff 100%);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 24px;
  z-index: 99999999;

  .title-top {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:last-child{
      color: #0089FF;
      font-size: 14px;
    }
  }
}
.padding-bot{
  padding-bottom: 16px;
}
.file-list {
  .title{
    margin-bottom: 10px;
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 16px;

}

.file-item {
  width: 368px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d6d9e5;
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 12px;
  // margin: 5px;
  margin-bottom: 5px;
  position: relative;

  .right-icon {
    .success {
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 32px;
    }
    // .delete {
    //   display: none;
    //   position: absolute;
    //   right: 8px;
    //   top: 8px;
    //   width: 14px;
    //   height: 14px;
    // }
  }
  // &:hover .delete {
  //   display: block;
  // }
  // &:hover .success {
  //   display: none;
  // }
  .file-icon {
    margin-right: 8px;
    img {
      width: 38px;
      height: 38px;
    }
  }
  .file-content {
    flex: 1;
    .text {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      font-size: 14px;
      div:nth-child(1) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .progress {
      margin-top: 4px;
    }
  }
}
</style>
