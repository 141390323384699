/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-05-01 14:11:34
 * @LastEditors: wenwen
 * @LastEditTime: 2024-05-05 16:57:10
 */
const state = {
  resourseFilesObj: {
    fileList: []
  }, // 资源管理
  koneledgeBaseFilesObj: {
    fileList: []
  }, // 知识库
  isShowRightFiles: false, // 离开上传文件页面显示缩小框
  disableUpload: {}
}
const mutations = {
  CHNAGEREOURCEFILESOBJ (state, payload) {
    state.resourseFilesObj = payload
  },
  CHANGEKONWLEDGEBASEFILESOBJ (state, payload) {
    state.koneledgeBaseFilesObj = payload
  },
  CHANGEISSHOWRIGHTFILES (state, payload) {
    state.isShowRightFiles = payload
  },
  CHNAGEDISABLEUPLOAD (state, payload) {
    state.disableUpload = payload
  }
}
export default {
  namespaced: true,
  state,
  mutations
}
